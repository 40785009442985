// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module;
import {Box,Container,Fade} from '@mui/material';

// TODO: Import MUI icon;

// TODO: Import custom component
import Slogan1 from './Slogan1.webp';
import Slogan2 from './Slogan2.webp';
import Slogan3 from './Slogan3.webp';
import Slogan4 from './Slogan4.webp';

// Import context
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

export default function ContainerFirst() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const [showedIndex, setShowedIndex] = React.useState(0);
    const items = [
        {
            Img: Slogan1,
            Text: Text.Home.Slogans.Slogan1[context.language],
        },
        {
            Img: Slogan2,
            Text: Text.Home.Slogans.Slogan2[context.language],
        },
        {
            Img: Slogan3,
            Text: Text.Home.Slogans.Slogan3[context.language],
        },
        {
            Img: Slogan4,
            Text: Text.Home.Slogans.Slogan4[context.language],
        },
    ];
    const fadeInterval = 2500;

    // TODO: Define event
    React.useEffect(() => {
        setTimeout(() => {
            setShowedIndex((showedIndex + 1) % items.length);
        }, fadeInterval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showedIndex]);

    // TODO: Define function

    // TODO: Return component
    return (
        <Container sx={{ height: `calc(100vw * 6 / 16)` }}>
            {
                items.map((item, i) => (
                    <Fade
                        in={i === showedIndex}
                        key={i}
                        timeout={{
                            enter: 1000,
                            exit: 1000
                        }}
                    >
                        <Box
                            sx={{
                                alignItems: 'center',
                                backgroundImage: `url("${item.Img}")`,
                                backgroundSize: 'cover',
                                display: 'flex',
                                height: `calc(100vw * 6 / 16)`,
                                justifyContent: 'center',
                                left: 0,
                                position: 'absolute',
                                //top: 0,
                                width: 1,
                            }}
                        >
                            {/*<Typography
                                component="p"
                                gutterBottom
                                sx={{
                                    color: '#FFF',
                                    fontWeight: 'bold',
                                    fontSize: { xs: '2.5rem', md: '4rem', lg: '6rem' },
                                    textShadow: '0px 0px 10px #000',
                                }}
                                variant="h1"
                            >
                                {item.Text}
                            </Typography>*/}
                        </Box>
                    </Fade>
                ))
            }
        </Container>
    );
}