// TODO: Import React module
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: Import MUI module
import { Box, Container, Grid, Stack, Typography } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import ImgAMOMENT from './CaseSharing/ImgAMOMENT.webp';
import ImgBoBunBeef from './CaseSharing/ImgBoBunBeef.webp';
import ImgHummingbird from './CaseSharing/ImgHummingbird.webp';
import ImgKowloonTrueLightSchool from './CaseSharing/ImgKowloonTrueLightSchool.jpg';
import ImgTICA from './CaseSharing/ImgTICA.webp';

import TypographyTitle from '../common/TypographyTitle.js';
import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';

export default function ContainerCaseSharing() {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const images = {
        AMOMENT: ImgAMOMENT,
        BoBunBeef: ImgBoBunBeef,
        Hummingbird: ImgHummingbird,
        KowloonTrueLightSchool: ImgKowloonTrueLightSchool,
        TICA: ImgTICA,
    };

    // TODO: Define event
    const navigate = useNavigate();

    // TODO: Define function

    // TODO: Return component
    return (
        <Container sx={{ mt: 2 }}>
            <TypographyTitle>{Text.Home.CaseSharing.Title[context.language]}</TypographyTitle>
            <Grid container spacing={2}>
                {
                    Object.entries(Text.Home.CaseSharing.Items).map(([id,item]) => (
                        <Grid item key={id} xs={6} md={3}>
                            <Stack onClick={() => { navigate(`/CaseSharing#${id}`) } } sx={{height:1}}>
                                <Box alt="Thumb" component="img" src={images[id]} sx={{ aspectRatio: '16/9', objectFit: 'cover', width: 1 }} />
                                <Box sx={{ backgroundColor: 'primary.light', flex:1 }}>
                                    <Typography component="p" sx={{ color: 'white', fontWeight: 'bold', mt: 1, textAlign: 'center' }} variant="h6">
                                        {item.Name[context.language]}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', m: 2, mt: 1 }}>
                                        {
                                            item.Desc[context.language].map((text, j) => (
                                                <React.Fragment key={j}>
                                                    {j > 0 && <Typography color="white" sx={{ mx: 1 }} variant="body1">|</Typography>}
                                                    <Typography color="white" variant="body1">{text}</Typography>
                                                </React.Fragment>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </Stack>
                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    );
}