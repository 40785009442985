export const Text = {
    Common: {
        Close: {
            CHI: '關閉',
            ENG: 'Close',
        },
        CompanyFullName: {
            CHI: '嗎哪工作室(香港)有限公司',
            ENG: 'AFG Creative House(Hong Kong) Company Ltd.',
        },
        CompanyShortName: 'AFG',
        Copyright: ['AFG Creative House(Hong Kong) Company Ltd.', 'Copyright ©2024 All rights reserved'],
        More: {
            CHI: '了解更多',
            ENG: 'More',
        },
    },

    Home: {
        Title: {
            CHI: '主頁',
            ENG: 'Home',
        },
        Slogans: {
            Slogan1: {
                CHI: '唯有熱情，觸動人心',
                ENG: 'XXXXXXXXXX',
            },
            Slogan2: {
                CHI: '文案 沒有公式',
                ENG: 'XXXXXXXXXX',
            },
            Slogan3: {
                CHI: '懂得說故事 比有故事重要',
                ENG: 'XXXXXXXXXX',
            },
            Slogan4: {
                CHI: '洞察力 共鳴感 同理心',
                ENG: 'XXXXXXXXXX',
            },
        },
        AboutUs: {
            Title: {
                CHI: '關於我們',
                ENG: 'Introducing',
            },
            Content: {
                CHI: [
                    '我們是一間本地的公關公司，致力透過經營與傳媒及各持份者的關係，提升品牌價值。創辦人接近十年的媒體經驗，深諳傳媒生態，無論取材角度，抑或連繫時下當紅的藝人合作，均能為品牌塑造新聞價值，引起熱話，爭取最大化曝光。',
                    '除了媒體觸覺及龐大的人脈網絡，我們十分擅長以文案為品牌塑造形象，透過簡單的字句，為宣傳活動生色不少。'
                ],
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            },
        },
        ContentMarking: {
            Title: {
                CHI: '什麼是內容行銷?',
                ENG: 'XXXXXXXXXX',
            },
            Content: [{
                CHI: '優質內容',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            }, {
                CHI: '向受眾提供價值',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            }, {
                CHI: '增加與受眾互動',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            }, {
                CHI: '提高銷售(回頭)率',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            }]
        },
        Suitable: {
            Title: {
                CHI: '內容行銷適合您公司嗎?',
                ENG: 'XXXXXXXXXX',
            },
            Content: [{
                CHI: '比傳統行銷較低成本',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            }, {
                CHI: '願意用時間建立品牌',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            }, {
                CHI: '希望建立長遠客戶關係',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            }, {
                CHI: '渴望增加銷售（回頭）率',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            }]
        },
        WhyChooseUs: {
            Title: {
                CHI: '為何選擇我們?',
                ENG: 'XXXXXXXXXX',
            },
            Content: {
                CHI: '我們精於創作個性化內容，發掘品牌獨特性，創作團隊擁有豐富的電視節目創作經驗，擅長掌握及迎合不同顧客層口味。',
                ENG: 'XXXXXXXXXXXXXXXXXXXXX',
            },
        },
        CaseSharing: {
            Title: {
                CHI: '精選個案',
                ENG: 'XXXXXXXXXX',
            },
            Items: {
                KowloonTrueLightSchool: {
                    Name: { CHI: '九龍真光中學', ENG: 'XXXXXXXXXX', },
                    Desc: {
                        CHI: ['創作故事大鋼', '策劃短片內容', '撰寫稿件', '現場拍攝', '剪接影片', '後期製作'],
                        ENG: 'XXXXXXXXXX',
                    },
                },
                /*Hummingbird: {
                    Name: { CHI: '蜂鳥資本', ENG: 'XXXXXXXXXX', },
                    Desc: {
                        CHI: ['名人合作', '宣傳片製作', '品牌文案撰寫'],
                        ENG: 'XXXXXXXXXX',
                    },
                },*/
                TICA: {
                    Name: { CHI: '天加思茂特香港有限公司', ENG: 'XXXXXXXXXX', },
                    Desc: {
                        CHI: ['媒體關係', '提升品牌價值'],
                        ENG: 'XXXXXXXXXX',
                    },
                },
                BoBunBeef: {
                    Name: { CHI: '堡品牛腩', ENG: 'XXXXXXXXXX', },
                    Desc: {
                        CHI: ['媒體關係', '公關活動策劃', '品牌文案撰寫'],
                        ENG: 'XXXXXXXXXX',
                    },
                },
                AMOMENT: {
                    Name: { CHI: 'A MOMENT', ENG: 'XXXXXXXXXX', },
                    Desc: {
                        CHI: ['媒體關係'],
                        ENG: 'XXXXXXXXXX',
                    },
                },
            },
        },
        ContactUs: {
            Title: {
                CHI: '與我們聯絡',
                ENG: 'Leave Us a Message',
            },
            Name: {
                CHI: '你的名字',
                ENG: 'Your Name',
            },
            Email: {
                CHI: '你的電郵',
                ENG: 'Your Email',
            },
            ContactNo: {
                CHI: '你的電話號碼',
                ENG: 'Your Contact No.',
            },
            Subject: {
                CHI: '標題',
                ENG: 'Subject',
            },
            Message: {
                CHI: '訊息',
                ENG: 'Your Message',
            },
            Send: {
                CHI: '發出',
                ENG: 'Send',
            },
            PleaseFill: {
                CHI: '請填寫此欄',
                ENG: 'Please fill in the blank',
            },
            PleaseFillContact: {
                CHI: '請提供電郵或電話號碼作聯絡之用',
                ENG: 'Please provide Email or Contact No for us',
            },
            EmailSent: {
                CHI: '已傳送訊息，如想儘快取得聯繫，請致電9036 8842直接查詢',
                ENG: 'Message has been delivered. Please call 9036 8842 if you want to get in touch as soon as possible.',
            },
        }
    },

    AboutUs: {
        Title: {
            CHI: '關於我們',
            ENG: 'Introducing',
        },
        Content: {
            CHI: [
                'AFG Creative House是一間本地公關公司，致力透過經營與傳媒及各持份者的關係，提升品牌價值。在AFG Creative House，您可以享有我們的三大優勢：',
                '1. 廣而深的人脈網絡：我們與影視紅人、KOL均有聯繫，而且與各大線上、線下媒體保持良好關係，每當連繫藝人與品牌合作，便能引起熱話，達致雙贏。',
                '2. 媒體觸覺：創辦人接近十年的媒體經驗，深諳傳媒生態，無論取材角度，抑或活動焦點，均能為品牌塑造新聞價值，爭取最大化曝光。',
                '3. 精準的文案能力：無論是活動的宣傳語句，還是影片的核心信息，我們十分擅長以文案為品牌塑造形象，透過簡單的字句，傳遞品牌價值予目標受眾。',
            ],
            ENG: 'XXXXXXXXXXXXXXXXXXXXXXXX',
        },
        Founder: {
            Title: {
                CHI: '我們的創辦人',
                ENG: 'Our founder',
            },
            Content: {
                CHI: '我們的創辦人Catherine曾在香港電視廣播有限公司(TVB)擔任節目資料撰稿，擁有豐富的電視節目內容創作經驗。憑藉豐富的媒體經驗和敏銳觸覺，我們致力透過傳媒關係與宣傳文案，為品牌建立長青形象。',
                ENG: 'XXXXXXXXXXXXXXXXXX',
            },
            More: {
                CHI: '了解更多',
                ENG: 'More',
            },
            ContentMore: {
                CHI: 'Catherine對室內設計擁有濃厚的興趣，並且在這個領域擁有豐富的專業知識和敏銳的觸覺。她曾拍攝和採訪過相關的主題，並且在TVB的節目「安樂蝸」中擔任過資料撰稿員，展現出她在室內設計領域的卓越表現。她的熱情和專業精神推動了公司的發展，使得我們能夠提供最優質的室內設計服務，並為客戶創造出最理想的生活空間。',
                ENG: 'XXXXXXXXXXXXXXXXXXXX',
            },
            OwnSweetHomeDuties: {
                CHI: [
                    '負責安樂蝸的資料撰稿',
                    '採訪、拍攝超過200個住宅單位',
                    '挑選有特色的設計單位拍攝',
                    '事前訪問室內設計師，了解設計概念',
                    '撰寫主持對白，突出單位的設計特色',
                ],
                ENG: [
                    'Responsible for the copywriting of Own Sweet Home',
                    'Interviewed and filmed more than 200 residential units',
                    'Choose a unique design unit for shooting',
                    'Visit the interior designer to understand the design concept',
                    'Write the host dialogue and highlight the design features of the unit',
                ],
            },
            ParticipatedPrograms: {
                CHI: '創辦人曾參與的節目',
                ENG: 'Programs that have participated in the production',
            },
        },
    },

    AFGHome: {
        Title: {
            CHI: 'AFG Home',
            ENG: 'AFG Home',
        },
        Articles: {
            CHI: '主題文章',
            ENG: 'Articles',
        },
        Magazine: {
            CHI: '線上雜誌',
            ENG: 'Online Magazine',
        },
    },

    CaseSharing: {
        Categories: [
            {
                Title: { CHI: '教育機構', ENG: 'Education Institution', },
                Cases: [
                    {
                        ID: 'KowloonTrueLightSchool',
                        Services: { CHI: ['影片製作'], ENG: [], },
                        Title: {
                            CHI: '九龍真光中學',
                            ENG: 'Kowloon True Light School',
                        },
                    },
                    {
                        ID: 'ChanKwongKindergarten',
                        Services: { CHI: ['影片製作'], ENG: [], },
                        Title: {
                            CHI: '中華基督教會元朗堂朗屏邨真光幼稚園',
                            ENG: 'Yuen Long Church (CCC) Long Ping Estate Chan Kwong Kindergarten',
                        },
                    },
                    {
                        ID: 'WFJosephLeePrimarySchool',
                        Services: { CHI: ['影片製作'], ENG: [], },
                        Title: { CHI: '和富慈善基金李宗德小學', ENG: 'W F Joseph Lee Primary School', },
                    },
                ],
            },
            {
                Title: { CHI: '非牟利機構', ENG: 'NGO', },
                Cases: [
                    {
                        ID: 'AKA',
                        Services: { CHI: ['影片製作'], ENG: [], },
                        Title: { CHI: '香港仔坊會—良躍社區藥房', ENG: '香港仔坊會—良躍社區藥房', },
                    },
                    {
                        ID: 'PoLeungKuk',
                        Services: { CHI: ['影片製作'], ENG: [], },
                        Title: { CHI: '保良局', ENG: 'Po Leung Kuk', },
                    },
                    {
                        ID: 'Orbis',
                        Services: { CHI: ['影片製作'], ENG: [], },
                        Title: { CHI: '奧比斯', ENG: 'Orbis', },
                    },
                    {
                        ID: 'WofooFoundation',
                        Services: { CHI: ['影片製作'], ENG: [], },
                        Title: { CHI: '和富慈善基金', ENG: 'Wofoo Foundation', },
                    },
                ],
            },
            {
                Title: { CHI: '地產及室內設計', ENG: 'Real Estate and Interior Design', },
                Cases: [
                    {
                        ID: 'ATHome',
                        Services: { CHI: ['影片製作'], ENG: [], },
                        Title: { CHI: 'ATHome', ENG: 'ATHome', },
                    },
                    {
                        ID: 'Hummingbird',
                        Services: { CHI: ['名人合作', '宣傳片製作', '品牌文案撰寫'], ENG: [], },
                        Title: { CHI: '蜂鳥資本', ENG: 'Hummingbird', },
                    },
                ],
            },
            {
                Title: { CHI: '其他', ENG: 'Other', },
                Cases: [
                    {
                        ID: 'AMOMENT',
                        Services: { CHI: ['媒體關係'], ENG: [], },
                        Title: { CHI: 'A MOMENT', ENG: 'A MOMENT', },
                    },
                    {
                        ID: 'BoBunBeef',
                        Services: { CHI: ['媒體關係', '公關活動策劃', '品牌文案撰寫'], ENG: [], },
                        Title: { CHI: '堡品牛腩', ENG: 'Bo Bun Beef', },
                    },
                    {
                        ID: 'TICA',
                        Services: { CHI: ['媒體關係', '提升品牌價值'], ENG: [], },
                        Title: { CHI: '天加思茂特香港有限公司', ENG: 'TICA-SMARDT HONG KONG LIMITED', },
                    },
                ],
            },
        ],

        Title: { CHI: '個案分享', ENG: 'Case Sharing', },

        AKA: {
            Name: { CHI: '香港仔坊會', ENG: 'AKA', },
            Intro: {
                Content: {
                    CHI: [
                        '社區藥房是現行想推動的政策一之，但香港對其操作及好處未太熟悉，因此，我們為其中一間社區藥房：良躍社區藥房拍攝兩條影片，分別以訪問形式帶出受助者在此計劃得到的好處；及一條全動畫短片，以淺白用語介紹流程操作。'
                    ],
                    ENG: [],
                }
            },
            Videos: [
                {
                    URL: 'https://www.youtube.com/watch?v=flOUbxqDUD4',
                },
                {
                    URL: 'https://www.youtube.com/watch?v=jihzMhelzs0',
                },
            ],
        },
        AMOMENT: {
            Name: { CHI: 'AMOMENT', ENG: 'AMOMENT', },
            Intro: {
                Title: { CHI: '邀請傳媒報導', ENG: 'XXXXXXX', },
                Content: {
                    CHI: 'A MOMENT 是一間以荷蘭為主題的家品店，於疫情時進駐屯門商場店，最需要獲得焦點報導；因此，我們為其撰寫新聞稿，邀請傳媒報導。其中包括TVB J2室內設計節目 <安樂蝸>；以及 iMoney 智富雜誌 <上位生意經> 兩版全頁專訪報導。',
                    ENG: 'XXXXXXXXXX',
                },
            },
        },
        ATHome: {
            Name: { CHI: 'AT Home Design', ENG: 'AT Home Design', },
            Intro: {
                Title: { CHI: '影片製作', ENG: '', },
                Content: {
                    CHI: [
                        '我們透過拍攝完工單位，讓客戶更深入了解設計師的風格和能力，同時為室內設計公司建立專業形象，提升品牌價值和市場競爭力。',
                        '在影片製作方面，我們將從文案創作、展示單位風格、設計師訪問內容、到畫面文字等多方面進行精密編排，以呈現最佳效果和品牌形象。我們的文案創作團隊會根據客戶需求和目標，撰寫吸引目標客戶群的影片內容。'
                    ],
                    ENG: [],
                }
            },
            Videos: [
                {
                    Title: { CHI: '商場廣告', ENG: 'Advertisement in Shopping Mall', },
                    URL: 'https://www.youtube.com/watch?v=tlKVCNR8l5I',
                },
                {
                    Title: { CHI: '設計師訪問', ENG: 'Designer Visit', },
                    URL: 'https://www.youtube.com/watch?v=C1Ia_kJdeV4',
                },
                {
                    Title: { CHI: '海之戀單位設計', ENG: 'Interior Design of Ocean Pride', },
                    URL: 'https://www.youtube.com/watch?v=fzZHsdVxuKE',
                },
            ],
            /*VideoProduction: {
                Title: {
                    CHI: '影片製作',
                    ENG: 'Video Production',
                },
                Content: {
                    CHI: '我們透過拍攝完工單位，讓客戶更深入了解設計師的風格和能力，同時為室內設計公司建立專業形象，提升品牌價值和市場競爭力。我們專業的攝影師和創意團隊會負責拍攝、編輯和呈現影片，讓客戶能夠直觀地感受設計師的專業能力和風格。<br /><br />在影片製作方面，我們將從文案創作、展示單位風格、設計師訪問內容、到畫面文字等多方面進行精密編排，以呈現最佳效果和品牌形象。我們的文案創作團隊會根據客戶需求和目標，撰寫生動有趣的文字內容，增加影片的趣味性和觀看度。在展示單位風格和設計師訪問方面，我們將根據客戶要求和設計師的風格特點進行拍攝和編輯，展現出最佳的設計效果和風格。<br /><br />我們的目標是透過影片展示，為客戶建立專業形象和品牌價值，同時幫助客戶推廣設計師的風格和能力，提高品牌知名度和市場競爭力。',
                    ENG: 'XXXXXXXXXX',
                },
            },*/
        },
        BoBunBeef: {
            Name: { CHI: '堡品牛腩', ENG: 'Bo Bun Beef', },
            Description: {
                CHI: ['品牌定位', '邀請傳媒報導', '活動策劃', '品牌文案撰寫',],
                ENG: 'XXXXXXXXXXX',
            },
            Content: {
                CHI: ['堡品牛腩是一間位於石門的餐廳，品牌希望吸引年青一代欣賞香港的地道美食——牛腩。'],
                ENG: 'XXXXXXXXXXX',
            },
            BrandPositioning: {
                Title: { CHI: '品牌定位', ENG: 'Brand Positioning', },
                Content: {
                    CHI: '街上的牛腩粉麵店比比皆是，如何突出品牌是首要考慮；因此，我們以『85度  最好的溫度』的文案，打造品牌是牛腩專家，在顧客心中留下深刻印象。',
                    ENG: 'XXXXXXXXXX',
                },
            },
            Wall: {
                Title: { CHI: '牆畫內容創作', ENG: 'XXXXXXXX', },
                Content: {
                    CHI: ['店內一幅大油畫，乃是店舖四大特色：鹵水料、炆製過程、85度  最好的溫度以及手炒辣椒油為重點，塑造品牌在製作牛腩上的用心。'],
                    ENG: 'XXXXXXXXXX',
                },
            },
            Opening: {
                Title: {
                    CHI: '統籌及主持開幕禮',
                    ENG: 'Opening Ceremony Coordinating and Hosting',
                },
                Content: {
                    CHI: '在開幕禮當日，我們負責策劃活動流程、撰寫司儀稿、擔任司儀及拍下花絮相片，熱鬧情況成功在當區製造熱話。',
                    ENG: 'XXXXXXXXXX',
                },
            },
            Promotional: {
                Title: {
                    CHI: '季度宣傳品',
                    ENG: 'Quarterly Promotional Materials',
                },
                Content: {
                    CHI: '為符合牛腩專家的定位，店舖每季均推出牛腩特色美食，而我們因應產品特色而製作宣傳品。',
                    ENG: 'XXXXXXXXXX',
                },
            },
            Mooncake: {
                Title: { CHI: '堡品牛腩陳皮月餅', ENG: 'XXXXXXXXXXXX', },
                Content: {
                    CHI: [
                        '堡品陳皮牛腩月餅乃堡品牛腩全港首創，品牌希望透過月餅，讓大眾嘗試到牛腩有不同食法。',
                        '但月餅雖然夠創新，單以創新度卻不足以帶起話題，因此，我們以『團聚話題由舌尖開始』，帶出中秋佳節，由一個月餅打開話匣子，重拾家人飯聚的溫馨。配合中秋佳節，我們更內附燈謎紙，讓月餅成為又好吃又好玩的中秋必備佳品。',
                        '區內，我們舉辦了試食會，邀請附近街坊一嘗創新的牛腩口味。',
                        '而對外，我們亦拍攝一輯食物硬照，再撰寫新聞稿，邀請傳媒採訪報導。是次成功得到TVB J2 關注關注組、U Food，明報、經濟日報、MetroDaily等報導。後來，更吸引不少『小紅書』的網紅探店。',
                    ],
                    ENG: 'XXXXXXXXXX',
                },
            },








            SocialMedia: {
                Title: {
                    CHI: '社交平台內容創作',
                    ENG: 'Social Media Content Creation',
                },
                Content: {
                    CHI: '我們為堡品牛腩的社交平台創作豐富的內容，包括文字與圖片等，以提高品牌知名度和粉絲互動性。我們的內容創作團隊將根據品牌形象和消費者需求，精心設計和編排內容，並提供定期更新和優化，以確保品牌的活躍度和影響力。',
                    ENG: 'XXXXXXXXXX',
                },
            },
        },
        ChanKwongKindergarten: {
            Name: {
                CHI: '中華基督教會元朗堂朗屏邨真光幼稚園',
                ENG: 'Yuen Long Church (CCC) Long Ping Estate Chan Kwong Kindergarten',
            },
            Intro: {
                CHI: ['幼稚園正面對收生不足的危機，如何讓家長透過不同角度了解到校園生活漸趨重要。因此，我們為中華基督教會元朗堂朗屏邨真光幼稚園拍攝兩條短片，分別以校園生活及教育理念為題，希望讓區內家長能在短短數分鐘，便了解幼稚園的核心教育理念，更重要的是，讓家長透過了影片，看到小朋友的開心校園生活，對小朋友即將成長的環境充滿信心'],
                ENG: [],
            },
            Videos: [
                {
                    Index: { CHI: '影片一', ENG: 'Video 1', },
                    Title: { CHI: '朗真快樂的一天', ENG: '朗真快樂的一天', },
                    Description: { CHI: '以學生一天的校園生活，向家長展現朗真的多元化活動。', ENG: '', },
                    URL: 'https://www.youtube.com/watch?v=rCSqC87R9ZE',
                },
                {
                    Index: { CHI: '影片二', ENG: 'Video 2', },
                    Title: { CHI: '生命教育在朗真', ENG: '生命教育在朗真', },
                    Description: { CHI: '透過學校四大方向，向家長展現朗真的生命教育理念。', ENG: '', },
                    URL: 'https://www.youtube.com/watch?v=CwE1rdxz-IU',
                },
            ],
        },
        Hummingbird: {
            Name: { CHI: '蜂鳥資本', ENG: 'Hummingbird', },
            Description: {
                CHI: ['連繫名人與品牌合作', '宣傳片製作', '品牌文案撰寫',],
                ENG: 'XXXXXXXXXXXX',
            },
            Content: {
                CHI: ['蜂鳥資本首個工廈翻新項目O’Nest，需要有獨特角度突出樓盤優勢，投資者希望宣傳片既保持專業度，同時不失年輕感。因此，我們為其發掘樓盤優勢，撰寫售樓書，而且連繫有『最索經理人』之稱的林寶玉小姐拍攝宣傳短片。'],
                ENG: 'XXXXXXXXXXX',
            },









            SalesBrochureWriting: {
                Title: { CHI: '售樓書撰寫', ENG: 'Sales Brochure Writing', },
                Intro: {
                    CHI: 'O\'Nest是蜂鳥資本旗下的一個工廈發展項目，我們擔任其售樓書撰寫的合作夥伴。我們致力於為O\'Nest提供最佳的售樓書創作方案，以展示其獨特的品牌價值和優勢，並吸引更多的潛在客戶。',
                    ENG: 'XXXXXXXXXX',
                },
                Content1: {
                    CHI: '我們了解客戶需求，與ONest的團隊進行溝通，了解他們的品牌價值、目標客戶和市場競爭情況。我們收集相關的市場資料和競爭對手的售樓書，以對比和參考。',
                    ENG: 'XXXXXXXXXX',
                },
                Content2: {
                    CHI: '我們設計一個適合ONest的售樓書架構，包括封面、目錄、簡介、位置、設施、平面圖和售後服務等部分。我們會確保售樓書的架構清晰、易讀、易懂。<br /><br />而我們的文案創作團隊會根據售樓書的架構，展示O\'Nest的品牌價值和獨特優勢，包括工作方式、綠色設計、智能家居、社區週邊設施等。我們會使用豐富的圖片、圖表和設計元素來加強內容的吸引力和可視性。',
                    ENG: 'XXXXXXXXXX',
                },
                Content3: {
                    CHI: '我們的設計團隊負責售樓書的編排和設計，以確保售樓書的美感和易讀性。我們會運用最新的設計工具和技術，以營造一個現代、時尚和舒適的感覺。',
                    ENG: 'XXXXXXXXXX',
                },
                Content4: {
                    CHI: '在完成售樓書的初稿後，我們會與ONest的團隊進行審核和修改，以確保售樓書符合客戶的需求和期望。我們會根據客戶的反饋和建議進行修改和優化，直到客戶滿意為止。',
                    ENG: 'XXXXXXXXXX',
                },
            },
            VideoProduction: {
                Title: {
                    CHI: '影片內容撰稿',
                    ENG: 'Video Content Writing',
                },
                Video: [
                    {
                        Title: {
                            CHI: 'O\'Nest 傲晴 主持導覽',
                            ENG: 'Advertisement',
                        },
                        URL: 'https://youtu.be/vPCeBLChp-0',
                    },
                    {
                        Title: {
                            CHI: 'Your Space for BIG DREAMS',
                            ENG: 'Your Space for BIG DREAMS',
                        },
                        URL: 'https://youtu.be/H2VfcBj48Kc',
                    },
                ],
            },
        },
        KowloonTrueLightSchool: {
            Name: {
                CHI: '九龍真光中學',
                ENG: 'Kowloon True Light School',
            },
            Intro: {
                CHI: ['幼稚園正面對收生不足的危機，如何讓家長透過不同角度了解到校園生活漸趨重要。因此，我們為中華基督教會元朗堂朗屏邨真光幼稚園拍攝兩條短片，分別以校園生活及教育理念為題，希望讓區內家長能在短短數分鐘，便了解幼稚園的核心教育理念，更重要的是，讓家長透過了影片，看到小朋友的開心校園生活，對小朋友即將成長的環境充滿信心'],
                ENG: [],
            },
            Videos: [
                {
                    Title: { CHI: '七十五周年校慶宣傳片', ENG: '75th Anniversary Promotional Video', },
                    Description: { CHI: ['面向新世代，中學教育不再只是教授書本知識，更要從中啟發學生多元才能，培育個人素養；創校四分之三個世紀，九龍真光中學將傳統配合創新，教導學生既要秉承真光女兒的傳統美德，亦要開發潛能，迎接新世代的挑戰。是次宣傳片以日常的真光女兒上學生活開展，配合強勁節拍的音樂轉承，道出九龍真光中學的學生在學術、體育、音樂、STEAM均有多元發展，希望讓家長、學生及各持份者，更深入了解到：真光女兒隨時為未來做好準備。'], ENG: '', },
                    Services: {
                        CHI: [
                            '創作故事大鋼',
                            '策劃短片內容',
                            '撰寫稿件',
                            '現場拍攝',
                            '剪接影片',
                            '後期製作',
                        ],
                        ENG: [
                            '創作故事大鋼',
                            '策劃短片內容',
                            '撰寫稿件',
                            '現場拍攝',
                            '剪接影片',
                            '後期製作',
                        ],
                    },
                    URL: 'https://youtu.be/XAM82vQeZFk',
                },
                {
                    Title: { CHI: '九龍真光中學75周年校慶嘉年華會—花絮短片', ENG: 'Kowloon True Light Secondary School 75th Anniversary Carnival – Highlights Video', },
                    Description: { CHI: ['為慶祝75周年校慶，九龍真光中學於2024年2月25日在西九文化區舉行了盛大的年華會。這次校慶活動的目的是展示學生的才藝、凝聚真光人的情感，同時推廣精神健康教育。我們為其拍攝及製作花絮短片作記錄。'], ENG: '', },
                    Services: {
                        CHI: [
                            '現場拍攝',
                            '剪接影片',
                            '後期製作',
                        ],
                        ENG: [
                            '現場拍攝',
                            '剪接影片',
                            '後期製作',
                        ],
                    },
                    URL: 'https://youtu.be/_OtZDer8xA4',
                },
            ],
        },
        Orbis: {
            Name: { CHI: '奧比斯', ENG: 'Orbis', },
            Intro: {
                Content: {
                    CHI: [
                        '2023 年，奧比斯及香港大學眼科學系合作的「視」不宜遲——青光眼人工智能ROTA篩查計劃。我們為其創作及拍攝短片，跟影視名人蘇玉華小姐合作，提高大眾認知。',
                        '為教育大眾對青光眼的正確知識，創作及拍攝三集『醫生有話兒』，以訪問香港教授形式，一分鐘內精準解答大眾對青光眼的謎思。'
                    ],
                    ENG: [],
                }
            },
            Videos: [
                {
                    URL: 'https://www.youtube.com/watch?v=cgHsYxRY9yA',
                },
                {
                    URL: 'https://www.youtube.com/watch?v=fQIzedMzUHY',
                },
            ],
        },
        PoLeungKuk: {
            Name: { CHI: '保良局', ENG: 'Po Leung Kuk', },
            Intro: {
                Content: {
                    CHI: [
                        '特殊兒童教育彷彿時常被忽略了，但其實相關的教育機構可能需要付出更大心力協助小朋友融入社會，我們為保良局轄下三間特殊幼兒中心拍攝短片，分別以「幸福一刻」、「初心」、「橋」為題，讓捐獻者、家長及社會各界，更關注及支持這些機構的發展。'
                    ],
                    ENG: [],
                }
            },
            Videos: [
                {
                    URL: 'https://www.youtube.com/watch?v=yxrTEBtKu3k',
                },
                {
                    URL: 'https://www.youtube.com/watch?v=TG8Mbb4IcGA',
                },
            ],
        },
        TICA: {
            Name: { CHI: '天加思茂特香港有限公司', ENG: 'TICA-SMARDT HONG KONG LIMITED', },
            MediaExposure: {
                Title: { CHI: '邀請傳媒報導', ENG: 'XXXXXXXXX', },
                Intro: {
                    CHI: [
                        '天加思茂特香港有限公司是一間環境工程企業，多年來默默耕耘，雖在行內享負盛名，但大眾卻未必熟悉。適逢社會對減碳議題越來越關注，天加思茂特亦希望藉此進行更大眾化的環境工程教育。',
                        '因此，我們為其撰寫新聞稿，介紹背後品牌理念，在訪談間，得知品牌在疫情下對改裝醫院病房作出貢獻，所以在新聞稿中便強調此部份，最後成功邀請TVB 黃金節目《東張西望》的採訪。',
                        '在得到《東張西望》採訪後，大眾對天加思茂特香港有限公司有更深認識，2023年，更受邀參加<香港經濟日報企業大獎2023>並成功獲獎，獲得大篇幅報導。',
                    ],
                    ENG: 'XXXXXXXXXX',
                },
                Video: [
                    {
                        Title: { CHI: '《東張西望》訪問片段', ENG: 'Your Space for BIG DREAMS', },
                        URL: 'https://youtu.be/iyoS_rGQqSI',
                    },
                ],
            },
            CorporateImage: {
                Title: {
                    CHI: '企業形象',
                    ENG: 'Corporate Image',
                },
                Intro: {
                    CHI: '猶記得初合作時，客戶未有明確方向如何走入大眾視線，始終一間以研發、生產為主的中央空調公司，過往沒有著墨於大眾教育，但經過數次開會商討後，我們終於找到合適的定位，賦以品牌『與港人同行』的角色，透過報導其疫情期間的貢獻，將產品、服務逐一曝光於人前，今次榮獲香港經濟日報企業大獎，正是社會的認同，亦為品牌劃出一亮點！',
                    ENG: 'XXXXXXXXXX',
                },
            },
        },
        WFJosephLeePrimarySchool: {
            Name: { CHI: '和富慈善基金李宗德小學', ENG: 'W F Joseph Lee Primary School', },
            Intro: {
                Title: { CHI: '學校二十周年紀錄片', ENG: '', },
                Content: {
                    CHI: ['踏入創校二十周年，和富慈善基金李宗德小學希望讓家長、學生及各持份者溫故知新，了解到創辦人李宗德先生的教育夢。因此我們以『追夢』為題，負責創作及策劃短片：從概念開發、撰寫故事大鋼及訪問稿件，將學校理念轉化成具有影響力和觸動性的影片故事作品。'],
                    ENG: [],
                }
            },
            Intro2: {
                Title: { CHI: '一鏡到底 展現學生才藝', ENG: '', },
                Content: {
                    CHI: ['和富慈善基金李宗德小學很想以新穎的方式，向家長展現學生在校內的多元才能發展，所以我們為其策劃及製作一鏡到底的影片，展現成果。'],
                    ENG: [],
                }
            },
            Video: [
                {
                    URL: 'https://www.youtube.com/watch?v=0OJIBaD1H5o',
                },
                {
                    URL: 'https://www.youtube.com/watch?v=0_qVO4na5BM',
                },
            ],
        },
        WofooFoundation: {
            Name: { CHI: '和富慈善基金', ENG: 'Wofoo Foundation', },
            Intro: {
                Title: { CHI: '和富慈善基金二十五周年紀錄片', ENG: '', },
                Content: {
                    CHI: [
                        '『人和家富 民和國富』和富企業一直堅守的信念，和富慈善基金很想藉二十五周年的紀念，將『和』的信念再次傳遞出去。',
                        '承接和富慈善基金李宗德小學的作品大獲好評，辦學團體『和富慈善基金』於二十五周年時，再接再厲拍攝紀錄片，講述慈善基金背後理念。'
                    ],
                    ENG: [],
                }
            },
            Video: [
                {
                    /*Title: {
                        CHI: 'XXXXXXXXXXX',
                        ENG: 'XXXXXXXX',
                    },*/
                    URL: 'https://www.youtube.com/watch?v=9v9BXges5JM',
                },
            ],



            /*VideoProduction: {
                Title: {
                    CHI: '影片製作',
                    ENG: 'Video Production',
                },
                Content: {
                    CHI: 'XXXXXXXXXX',
                    ENG: 'XXXXXXXXXX',
                },
            },*/
        },
    },

    ContactUs: {
        Title: { CHI: '聯絡我們', ENG: 'Contact Us', },
        ContactNoTitle: { CHI: '聯絡電話', ENG: 'Contact No.', },
        ContactNo: '9036 8842',
        EmailTitle: { CHI: '聯絡電郵', ENG: 'Email', },
        Email: 'cath.lo@afgcreative.com',
        AddressTitle: { CHI: '公司地址', ENG: 'Address', },
        Address: {
            CHI: '葵涌葵樂街2-28號裕林工業中心B座12樓B239室',
            ENG: 'Room B239, 12/F, Block B, Yee Lim Industrial Centre, 2-28 Kwai Lok Street, Kwai Chung',
        },
    },

    OurServices: {
        Title: {
            CHI: '我們的服務',
            ENG: 'Our Services',
        },
        Services: {
            BrandCopywriting: {
                ID: 'BrandCopywriting',
                Title: { CHI: '品牌文案撰寫', ENG: 'XXXX', },
                Intro: [
                    { CHI: '透過撰寫品牌文案，吸引目標客群並提升品牌價值', ENG: 'XXXXXXXXXXXXXXXX', },
                    { CHI: '網站內容、售樓書、宣傳書刊等', ENG: 'XXXXXXXXXXXXXXXX', },
                    { CHI: '塑造社交媒體形象', ENG: 'XXXXXXXXXXXXXXXX', },
                ],
                Slogan: {
                    CHI: [
                        '『用最少的字，表達最多的信息』',
                    ],
                    ENG: 'XXXX',
                },
                Description: {
                    CHI: [
                        '我們深信，每個品牌均有獨特的個性，而文案正是展示個性的地方，所以品牌應有屬於自己的語調、用字，因為一字一句，全為品牌於無形中建立不可磨滅的形象。'
                    ],
                    ENG: 'XXXX',
                },
                Content: [
                    {
                        Title: { CHI: '撰寫品牌故事', ENG: 'XXXX', },
                        Content: {
                            CHI: ['品牌故事展示一間企業的價值所在，我們的團隊均有豐富的媒體撰稿經驗和專業知識，透過深入訪談和市場研究，精心打造每個品牌獨有的故事，以吸引目標客群並提升品牌價值。'],
                            ENG: 'XXXX',
                        },
                    },
                    {
                        Title: { CHI: '創作網頁內容', ENG: 'XXXX', },
                        Content: {
                            CHI: ['網站是一間企業的線上門面，往往給予顧客的消費信心；因此，透過網頁結構、SEO優化、有吸引力的內容，甚至使用者體驗等，都有助企業提高網站流量、轉換率、搜尋引擎評分和排名。'],
                            ENG: 'XXXX',
                        },
                    },
                    {
                        Title: { CHI: '創作社交媒體內容', ENG: 'XXXX', },
                        Content: {
                            CHI: ['社交媒體是接觸年青大眾的極佳渠道，持續輸出有價值的內容有助大眾對品牌留下良好印象。'],
                            ENG: 'XXXX',
                        },
                    },
                    {
                        Title: { CHI: '撰寫專業內容', ENG: 'XXXX', },
                        Content: {
                            CHI: ['售樓書、宣傳書刊等專業內容，通常被認為只需要精確、客觀和清晰的文字，但往往忽略需要將艱澀的概念，轉換成公眾易明的內容。我們的團隊，擅於利用電視節目製作的經驗，將難明意念，轉成淺明而吸引的內容傳遞予受眾。'],
                            ENG: 'XXXX',
                        },
                    },
                ],
            },
            MediaRelations: {
                ID: 'MediaRelations',
                Title: { CHI: '媒體關係', ENG: 'XXXX', },
                Intro: [
                    { CHI: '發掘具新聞價值的角度，吸引傳媒採訪，為品牌獲取最大化的曝光', ENG: 'XXXXXXXXXXXXXXXX', },
                    { CHI: '電視台、報章、雜誌正面報導', ENG: 'XXXXXXXXXXXXXXXX', },
                    { CHI: '人物專訪傳遞品牌價值', ENG: 'XXXXXXXXXXXXXXXX', },
                ],
                Slogan: {
                    CHI: [
                        '『正面而持續的媒體曝光，是傳遞品牌價值的最有效方法』',
                    ],
                    ENG: 'XXXX',
                },
                Content: [
                    {
                        Title: { CHI: '撰寫新聞稿', ENG: 'XXXX', },
                        Content: {
                            CHI: ['我們團隊有十年的傳媒經驗，善於為品牌發掘具新聞價值的角度，吸引傳媒採訪，加上與傳媒機構（包括電視台、線上/線下的報章雜誌）的緊密聯繫，能為品牌獲取最大化的正面曝光。'],
                            ENG: 'XXXX',
                        },
                    },
                    {
                        Title: { CHI: '人物專訪傳遞品牌價值', ENG: 'XXXX', },
                        Content: {
                            CHI: ['除了資訊報導，我們團隊亦擅長協助品牌代表接受人物專訪，包括於各大電視台節目亮相，及報章雜誌採訪，從而提高個人及品牌知名度，增加消費者信心。'],
                            ENG: 'XXXX',
                        },
                    },
                    {
                        Title: { CHI: '撰寫鱔稿', ENG: 'XXXX', },
                        Content: {
                            CHI: ['一份優質的鱔稿，能以軟性角度提升品牌的正面形象，並結合多種媒體形式進行傳播，以最大限度提高廣告宣傳效果。'],
                            ENG: 'XXXX',
                        },
                    },
                ],
            },
            BrandsCooperation: {
                ID: 'BrandsCooperation',
                Title: { CHI: '連繫名人與品牌合作', ENG: 'XXXX', },
                Intro: [
                    { CHI: '連繫藝人、KOL 出席活動、擔任代言人等，增加品牌認受性及吸引力', ENG: 'XXXXXXXXXXXXXXXX', },
                ],
                Slogan: {
                    CHI: [
                        '『名人效應越好，消費者對目標產品的購買意願越高』',
                    ],
                    ENG: 'XXXX',
                },
                Content: [
                    {
                        Title: { CHI: '', ENG: 'XXXX', },
                        Content: {
                            CHI: ['多年的傳媒工作，讓我們累積了廣而深的人脈網絡，有效連繫歌視紅人、KOL 與品牌合作，包括出席活動、開幕禮、擔任代言人、試用產品等，從而使品牌與大眾有進一步連繫，增加認受性及吸引力。'],
                            ENG: 'XXXX',
                        },
                    },
                ],
            },
            PromotionalVideoProduction: {
                ID: 'PromotionalVideoProduction',
                Title: { CHI: '宣傳片製作', ENG: 'XXXX', },
                Intro: [
                    { CHI: '電視台級製作團隊', ENG: 'XXXXXXXXXXXXXXXX', },
                    { CHI: '重視內容創作，以影片傳遞品牌價值', ENG: 'XXXXXXXXXXXXXXXX', },
                    { CHI: '透過文案控制拍攝成本', ENG: 'XXXXXXXXXXXXXXXX', },
                ],
                Slogan: {
                    CHI: [
                        '『用影像說故事』',
                    ],
                    ENG: 'XXXX',
                },
                Content: [
                    {
                        Title: { CHI: '', ENG: 'XXXX', },
                        Content: {
                            CHI: ['我們的拍攝團隊，均有豐富拍攝經驗，其中更包括電視台的廠景及外景拍攝，因此能精準拿掐鏡頭角度；而我們亦十分重視內容創作，透過主題設計、內容編排、訪問角度、撰稿等，以影片傳遞品牌價值。'],
                            ENG: 'XXXX',
                        },
                    },
                ],
            },
            EventPlanning: {
                ID: 'EventPlanning',
                Title: { CHI: '公關活動策劃', ENG: 'XXXX', },
                Intro: [
                    { CHI: '開幕禮、產品發佈會、媒體餐宴等', ENG: 'XXXXXXXXXXXXXXXX', },
                ],
                Slogan: {
                    CHI: [
                        '『一個成功的活動，能令各個持份者都得到滿足』',
                    ],
                    ENG: 'XXXX',
                },
                Content: [
                    {
                        Title: { CHI: '', ENG: 'XXXX', },
                        Content: {
                            CHI: ['我們期望，為品牌策劃活動時，不只讓參與者在參與當刻感到開心，而是當完成活動後，所有參與者，包括企業代表、股東、傳媒、顧客、公眾等，都仍會記得活動的某一瞬間，除了對品牌留下深刻印象，更使活動成為目標受眾的熱話。'],
                            ENG: 'XXXX',
                        },
                    },
                    {
                        Title: { CHI: '', ENG: 'XXXX', },
                        Content: {
                            CHI: ['在開幕禮、產品發佈會、媒體餐宴等活動中，我們提供一站式的服務， 包括由挑選場地、活動流程、場地佈置、安排司儀人選、撰寫司儀稿等，即使是再微小的細節，我們都願意為您行多一步。'],
                            ENG: 'XXXX',
                        },
                    },
                ],
            },
            TVProgramWriting: {
                ID: 'TVProgramWriting',
                Title: { CHI: '電視節目撰稿', ENG: 'XXXX', },
                Intro: [
                    { CHI: '外判節目寫稿', ENG: 'XXXXXXXXXXXXXXXX', },
                    { CHI: '包涵室內設計、飲食、旅遊等範疇', ENG: 'XXXXXXXXXXXXXXXX', },
                ],
                Slogan: {
                    CHI: [
                        '『電視節目的本質，就是讓大眾獲取有價值的資訊同時，得到娛樂』',
                    ],
                    ENG: 'XXXX',
                },
                Content: [
                    {
                        Title: { CHI: '', ENG: 'XXXX', },
                        Content: {
                            CHI: ['即使離開電視台後，我們的創辦人仍沒有忘記初心：透過電視節目，讓大眾感受到正能量。因此，我們亦承接外判節目的寫稿部份，負責整理資訊和聯絡各拍攝單位。透過我們的專業和細心，讓節目製作順利拍攝及播放，呈現出高品質的節目內容。'],
                            ENG: 'XXXX',
                        },
                    },
                    {
                        Title: { CHI: '', ENG: 'XXXX', },
                        Content: {
                            CHI: ['承接外判節目的類型十分多元化，包括室內設計、飲食、旅遊等範疇。'],
                            ENG: 'XXXX',
                        },
                    },
                ],
                ContentGrid1: {
                    CHI: [
                        '負責安樂蝸的資料撰稿',
                        '採訪、拍攝超過200個住宅單位',
                        '挑選有特色的設計單位拍攝',
                        '事前訪問室內設計師，了解設計概念',
                        '撰寫主持對白，突出單位的設計特色',
                    ],
                    ENG: [
                        'Responsible for the copywriting of Own Sweet Home',
                        'Interviewed and filmed more than 200 residential units',
                        'Choose a unique design unit for shooting',
                        'Visit the interior designer to understand the design concept',
                        'Write the host dialogue and highlight the design features of the unit',
                    ],
                },
            },






            /*ResearchWriting: {
                ID: 'ResearchWriting',
                Title: {
                    CHI: '撰寫稿件',
                    ENG: 'Writing Services',
                },
                Intro: [
                    {
                        CHI: '電視節目稿件',
                        ENG: 'XXXXXXXXXXXXXXXX',
                    },
                    {
                        CHI: '活動司儀稿件',
                        ENG: 'XXXXXXXXXXXXXXXX',
                    }
                ],
                Content: {
                    CHI: '「資料撰稿」是指撰寫關於特定主題的資料或報告，通常是為了提供給讀者有關該主題的詳細資訊。這種撰稿通常需要精確、客觀和清晰的文字，以便讀者能夠理解和應用這些資訊。撰寫資料撰稿需要收集、整理和分析大量的資料，以便能夠提供有用的見解和洞見。這種撰稿可以應用在許多不同的領域，例如學術、商業、科技和政治等，並且在這些領域中，資料撰稿被視為一種重要的溝通和知識傳播方式。<br><br>我們曾擔任節目製作的資料撰稿工作，負責整理和編輯相關資料，透過我們的專業和細心，節目製作順利拍攝及播放，呈現出高品質的節目內容。',
                    ENG: 'XXXXXXXXXXXXXXXXXXXX',
                },
                ParticipatedPrograms: {
                    CHI: '曾參與製作的節目',
                    ENG: 'Programs that have participated in the production',
                },
                WorkedTVStations: {
                    CHI: '曾合作過的電視台',
                    ENG: 'TV stations we have worked with',
                },
                OtherParticipatedPrograms: {
                    CHI: '其他節目',
                    ENG: 'Other programs',
                },
            },
            BrandStoryWriting: {
                ID: 'BrandStoryWriting',
                Title: {
                    CHI: '撰寫品牌故事',
                    ENG: 'Brand Story Writing',
                },
                Intro: [{
                    CHI: '突顯品牌的核心價值、使命和願景',
                    ENG: 'XXXXXXXXXXXXXXXXXX',
                }, {
                    CHI: '吸引及連結目標客戶群',
                    ENG: 'XXXXXXXXXXXXXXXXXX',
                }],
                Content: {
                    CHI: '我們公司提供品牌故事撰寫服務，透過深入訪談和研究，精心打造每個品牌獨有的故事，以吸引目標客群並提升品牌價值。我們的撰稿團隊擁有豐富的寫作經驗和行銷專業知識，能夠以獨特的角度、精準的策略，呈現出令人印象深刻的品牌形象。不論是新創公司還是成熟品牌，我們都能提供量身定制的服務，幫助客戶在市場競爭中脫穎而出。',
                    ENG: 'XXXXXXXXXXXXXXXX',
                },
            },
            VideoContentCreation: {
                ID: 'VideoContentCreation',
                Title: {
                    CHI: '影片內容創作',
                    ENG: 'Video Content Creation',
                },
                Intro: [{
                    CHI: '構思影片主題和故事線',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '策劃內容編排',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '增強品牌形象、擴大市場影響力和吸引目標受眾',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }],
                Content: {
                    CHI: '「影片內容創作」是指製作和創造影片內容，以傳達特定的信息、故事或娛樂性內容。這種創作可以應用在廣告、娛樂、教育和行銷等不同的領域，並且在數位媒體時代越來越受到重視。影片內容創作需要深入了解目標觀眾和目的，以便能夠製作出有吸引力和價值的影片。這種創作也需要考慮到影片製作的各個方面，例如劇本創作、攝影、音樂和後期製作等，以便能夠呈現出高品質的影片內容。',
                    ENG: 'XXXXXXXXXXXXXXXX',
                },
            },
            WebpageContentCreation: {
                ID: 'WebpageContentCreation',
                Title: {
                    CHI: '創作網頁內容',
                    ENG: 'Webpage Content Creation',
                },
                Intro: [{
                    CHI: '構思網頁地圖',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '透過文字、圖片、影片和其他媒體形式，以資訊及價值吸引目標客戶群',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '優化SEO和提升使用者體驗',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '提高網站流量、轉換率和排名',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }],
                Content: {
                    CHI: '網頁內容創作是指為網站或網頁撰寫有價值、有意義且吸引人的內容。這種內容可以包括文字、圖片、影片和其他媒體形式，以便提供有用的資訊、娛樂或其他價值給網站的訪問者。網頁內容創作需要深入了解目標受眾和網站的目的，以便能夠創作出有吸引力和價值的內容。這種創作也需要考慮到網頁的結構、SEO優化和使用者體驗等因素，以便能夠提高網站的流量、轉換率和排名。網頁內容創作是網站成功的重要因素之一，可以幫助網站建立品牌聲譽、增加訪客數量和提高網站價值。',
                    ENG: 'XXXXXXXXXXXXXXXX',
                },
                Sitemap: {
                    Title: {
                        CHI: '設計網站地圖',
                        ENG: 'Sitemap Design',
                    },
                    Intro: {
                        CHI: '本公司擁有專業的網站設計團隊，能夠為客戶提供高品質的網站地圖設計服務。我們會根據客戶的需求和網站結構，設計一份清晰、易於理解的網站地圖，包括網站的主要頁面、子頁面和內容分類等。我們的設計團隊擅長於使用簡潔、直觀的方式來呈現網站結構，讓客戶更加清晰地了解網站的結構和導航頁面，提高用戶體驗和網站可用性。',
                        ENG: 'XXXXXXXXXXXXXXXXXXXX.',
                    },
                },
                SEO: {
                    Title: {
                        CHI: '透過文案內容製定SEO關鍵字提高搜尋引擎評分',
                        ENG: 'Set SEO keywords through copywriting content to improve search engine ratings',
                    },
                    Intro: {
                        CHI: '本公司擁有專業的SEO優化團隊，能夠為客戶提供全面的網站SEO優化服務。我們會根據客戶的需求和目標關鍵詞，進行網站關鍵詞研究和競爭對手分析，制定符合SEO規範的內容優化、技術優化和外部優化等方案。我們的SEO團隊擅長於使用最新的SEO技術和方法，提高網站的排名和曝光率，吸引更多的有價值的流量和潛在客戶，提高網站的轉換率和商業價值。',
                        ENG: 'XXXXXXXXXXXXXXXXXXXX.',
                    },
                },
            },
            WritingServices: {
                ID: 'WritingServices',
                Title: {
                    CHI: '撰寫銷售文案',
                    ENG: 'Writing Services',
                },
                Intro: [{
                    CHI: '發掘獨特的宣傳角度',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '構思具吸引力的內容',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '包括售樓書、宣傳書刊、宣傳海報等',
                    ENG: 'XXXXXXXXXXXXXXXX',
                },],
                Content: {
                    CHI: '「撰寫專業內容」是指以專業的角度、知識和技能撰寫特定領域的內容，以便提供有價值的資訊和見解給讀者。這種內容可以應用在許多不同的領域，例如學術、商業、科技和醫療等，並且通常需要精確、客觀和清晰的文字，以便讀者能夠理解和應用這些資訊。撰寫專業內容需要對特定領域有深入的瞭解和研究，並且需要遵循相應的專業標準和要求。這種內容的撰寫可以幫助建立專業聲譽、增加品牌價值和吸引潛在客戶。',
                    ENG: 'XXXXXXXXXXXXXXXX',
                },
                SalesBrochure: {
                    Title: {
                        CHI: '撰寫售樓書',
                        ENG: 'Sales Brochure Writing',
                    },
                    Intro: {
                        CHI: '本公司擁有專業的撰寫團隊，能夠為客戶提供高品質的售樓書撰寫服務。我們會根據客戶的需求和物業特點，量身打造一份完整、詳盡的售樓書，包括物業基本資料、設施、價格、付款方式、申請程序、銷售政策和買賣條款等。我們的撰寫團隊擅長於用簡潔明瞭的語言和圖表來呈現物業的特點和優勢，讓客戶更加了解和信任物業，提高銷售轉化率。',
                        ENG: 'XXXXXXXXXXXXXXXXXXXX.',
                    },
                },
                PromotionalBooks: {
                    Title: {
                        CHI: '撰寫宣傳書刊',
                        ENG: 'Promotional Books Writing',
                    },
                    Intro: {
                        CHI: '本公司擁有專業的宣傳書刊撰寫團隊，能夠為客戶提供高品質的宣傳書刊撰寫服務。我們會根據客戶的需求和目標受眾，量身打造一份吸引人、具有說服力的宣傳書刊，包括內容策略、文案撰寫、排版設計和印刷製作等。我們的撰寫團隊擅長於用生動的故事、有趣的素材和精美的設計來吸引目標受眾的注意力，讓客戶的品牌和產品更加生動、有吸引力，提高市場競爭力。',
                        ENG: 'XXXXXXXXXXXXXXXXXXXX.',
                    },
                },
            },
            SocialMediaContentCreation: {
                ID: 'SocialMediaContentCreation',
                Title: {
                    CHI: '創作社交媒體內容',
                    ENG: 'Social Media Content Creation',
                },
                Intro: [{
                    CHI: '提高內容的曝光率、參與度和分享度',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }],
                Content: {
                    CHI: '社交媒體內容創作是指為社交媒體平台創作有價值、有意義且吸引人的內容。這種內容可以包括文字、圖片、影片和其他媒體形式，以便提供有用的資訊、娛樂或其他價值給社交媒體平台的用戶。社交媒體內容創作需要深入了解目標受眾和社交媒體平台的特點，以便能夠創作出有吸引力和價值的內容。這種創作也需要考慮到社交媒體平台的特定格式、用戶互動和趨勢等因素，以便能夠提高內容的曝光率、參與度和分享度。社交媒體內容創作是社交媒體營銷成功的重要因素之一，可以幫助建立品牌聲譽、增加用戶互動和提高社交媒體價值。',
                    ENG: 'XXXXXXXXXXXXXXXX',
                },
            },
            BrandBuilding: {
                ID: 'BrandBuilding',
                Title: {
                    CHI: '建立品牌',
                    ENG: 'Brand Building',
                },
                Intro: [{
                    CHI: '撰寫新聞稿',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '安排媒體採訪',
                    ENG: 'XXXXXXXXXXXXXXXX',
                }, {
                    CHI: '撰寫鱔稿',
                    ENG: 'XXXXXXXXXXXXXXXX',
                },],
                Content: {
                    CHI: '品牌建立是指透過設計、創造和推廣一個獨特的品牌形象和價值主張，來建立消費者對一個產品、服務或公司的信任和忠誠度。品牌建立的目的是創造一個有吸引力、可靠和獨特的品牌形象，並將其與公司的核心價值和使命相關聯。品牌建立需要深入了解目標受眾和市場環境，以便能夠創造出有吸引力和價值的品牌形象和故事。這種建立也需要考慮到品牌的產品設計、廣告宣傳、社交媒體行銷和客戶體驗等方面，以便能夠建立一個全面的品牌形象。品牌建立是公司成功的重要因素之一，可以幫助建立品牌聲譽、增加市場佔有率和提高公司價值。',
                    ENG: 'XXXXXXXXXXXXXXXX',
                },
                PressRelease: {
                    Title: {
                        CHI: '撰寫新聞稿',
                        ENG: 'Press Release Writing',
                    },
                    Intro: {
                        CHI: '撰寫新聞稿是一種書面形式的新聞傳播方式，通常用於向公眾傳達具有價值的資訊。我們與傳媒機構的緊密聯繫，可以為客戶撰寫具新聞寫作規範的新聞稿件，包括新聞的要素、標題、導語、正文、結尾等，以確保新聞稿的品質和效果。',
                        ENG: 'XXXXXXXXXXXXXXXXXXXX.',
                    },
                },
                MediaInterview: {
                    Title: {
                        CHI: '媒體採訪',
                        ENG: 'Media Interview',
                    },
                    Intro: {
                        CHI: '本公司可以為客戶安排媒體採訪，包括資料搜集、採訪、拍攝、錄音等多種形式，協助客戶與媒體建立聯繫並獲得更多曝光和宣傳機會。我們擁有豐富的媒體資源和行業經驗，可以為客戶提供個性化的媒體採訪方案，以滿足客戶的需求和目標。我們的專業團隊將負責與媒體溝通、安排採訪時間和地點、協調相關事宜等，以確保採訪的真實性、公正性和合法性，並最大限度地提升客戶的品牌形象和知名度。',
                        ENG: 'XXXXXXXXXXXXXXXXXXXX.',
                    },
                },
                Advertorial: {
                    Title: {
                        CHI: '撰寫鱔稿',
                        ENG: 'Advertorial Writing',
                    },
                    Intro: {
                        CHI: '本公司可以為客戶撰寫優質的鱔稿，以宣傳客戶的品牌、產品或服務。我們的專業團隊將根據客戶的需求和要求，撰寫具有新聞價值和吸引力的新聞稿，並結合多種媒體形式進行傳播。我們擁有豐富的鱔稿撰寫經驗和行業知識，可以為客戶提供個性化的鱔稿撰寫方案，以最大限度地提高廣告宣傳效果。',
                        ENG: 'XXXXXXXXXXXXXXXXXXXX.',
                    },
                },
            },*/
        },
    },

    Partnership: {
        Title: {
            CHI: '合作夥伴',
            ENG: 'Partnership',
        },
        Thankyou: {
            CHI: '以專業答謝合作夥伴的支持和信任',
            ENG: 'XXXXXXXXXXX',
        },
    }
}