// TODO: Import React module
import * as React from 'react';
import { useLocation } from 'react-router-dom';

// TODO: Import MUI module
import { Container, Grid, } from '@mui/material';

// TODO: Import MUI icon

// TODO: Import custom component
import ThumbAKA from './AKA/Thumb.jpg';
import ThumbAMOMENT from './AMOMENT/Thumb.jpg';
import ThumbATHome from './ATHome/Thumb.jpg';
import ThumbBoBunBeef from './BoBunBeef/Thumb.jpg';
import ThumbChanKwongKindergarten from './ChanKwongKindergarten/Thumb.jpg';
import ThumbHummingbird from './Hummingbird/Thumb.jpg';
import ThumbKowloonTrueLightSchool from './KowloonTrueLightSchool/Thumb.jpg';
import ThumbOrbis from './Orbis/Thumb.jpg';
import ThumbPoLeungKuk from './PoLeungKuk/Thumb.jpg';
import ThumbTICA from './TICA/Thumb.jpg';
import ThumbWFJosephLeePrimarySchool from './WFJosephLeePrimarySchool/Thumb.jpg';
import ThumbWofooFoundation from './WofooFoundation/Thumb.jpg';

import DialogAKA from './AKA/DialogCase';
import DialogAMOMENT from './AMOMENT/DialogCase';
import DialogATHome from './ATHome/DialogCase';
import DialogBoBunBeef from './BoBunBeef/DialogCase';
import DialogChanKwongKindergarten from './ChanKwongKindergarten/DialogCase';
import DialogHummingbird from './Hummingbird/DialogCase';
import DialogKowloonTrueLightSchool from './KowloonTrueLightSchool/DialogCase';
import DialogOrbis from './Orbis/DialogCase';
import DialogPoLeungKuk from './PoLeungKuk/DialogCase';
import DialogTICA from './TICA/DialogCase';
import DialogWFJosephLeePrimarySchool from './WFJosephLeePrimarySchool/DialogCase';
import DialogWofooFoundation from './WofooFoundation/DialogCase';

import { ContextApp } from '../common/ContextApp.js';
import { Text } from '../common/Text.js';
import BoxTitle from './BoxTitle';
import StackCase from './StackCase';

const ContainerCaseSharing = () => {
    // TODO: Define useContext
    const context = React.useContext(ContextApp);

    // TODO: Define variable
    const [openDialog, setOpenDialog] = React.useState(null);

    const location = useLocation();
    const hash = location.hash;

    const cases = {
        AKA: {
            Dialog: <DialogAKA openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbAKA,
        },
        AMOMENT: {
            Dialog: <DialogAMOMENT openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbAMOMENT,
        },
        ATHome: {
            Dialog: <DialogATHome openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbATHome,
        },
        BoBunBeef: {
            Dialog: <DialogBoBunBeef openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbBoBunBeef,
        },
        ChanKwongKindergarten: {
            Dialog: <DialogChanKwongKindergarten openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbChanKwongKindergarten,
        },
        Hummingbird: {
            Dialog: <DialogHummingbird openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbHummingbird,
        },
        KowloonTrueLightSchool: {
            Dialog: <DialogKowloonTrueLightSchool openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbKowloonTrueLightSchool,
        },
        Orbis: {
            Dialog: <DialogOrbis openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbOrbis,
        },
        PoLeungKuk: {
            Dialog: <DialogPoLeungKuk openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbPoLeungKuk,
        },
        TICA: {
            Dialog: <DialogTICA openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbTICA,
        },
        WFJosephLeePrimarySchool: {
            Dialog: <DialogWFJosephLeePrimarySchool openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbWFJosephLeePrimarySchool,
        },
        WofooFoundation: {
            Dialog: <DialogWofooFoundation openDialog={openDialog} setOpenDialog={setOpenDialog} />,
            Thumb: ThumbWofooFoundation,
        },
    };

    // TODO: Define event
    React.useEffect(() => {
        setOpenDialog(hash ? hash.substring(1) : null);
    }, [hash]);

    // TODO: Define function

    // TODO: Return component
    return (
        <Container sx={{ mt: 2 }}>
            {
                Text.CaseSharing.Categories.map(category =>
                    <React.Fragment key={category.Title.ENG}>
                        <BoxTitle>{category.Title[context.language]}</BoxTitle>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {
                                category.Cases.map(caseObj =>
                                    <Grid item key={caseObj.ID} xs={6} md={3}>
                                        <StackCase
                                            caseID={caseObj.ID}
                                            image={cases[caseObj.ID].Thumb}
                                            services={caseObj.Services[context.language]}
                                            setOpenDialog={setOpenDialog}
                                            title={caseObj.Title[context.language]}
                                        />
                                        {cases[caseObj.ID].Dialog}
                                    </Grid>
                                )
                            }
                        </Grid>
                    </React.Fragment>
                )
            }
        </Container>
    );
}

export default ContainerCaseSharing;