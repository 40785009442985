// TODO: Import React module
import * as React from 'react';

// TODO: Import MUI module
import Box from '@mui/material/Box';

// TODO: Import custom component
import ATHomeDesign from './Logos/ATHomeDesign.jpg';
import BoBunBeef from './Logos/BoBunBeef.jpg';
import Hummingbird from './Logos/Hummingbird.jpg';
import TICA from './Logos/TICA.jpg';

import AKA from './Logos/AKA.jpg';
import AMoment from './Logos/AMoment.jpg';
import AMPM from './Logos/AMPM.jpg';
import BestWishes from './Logos/BestWishes.jpg';
import Cedar from './Logos/Cedar.jpg';
import Chamberlain from './Logos/Chamberlain.jpg';
import ChanKwong from './Logos/ChanKwong.jpg';
import CMCLHK from './Logos/CMCLHK.jpg';
import DDPR from './Logos/DDPR.jpg';
import DecoFarmerStudio from './Logos/DecoFarmerStudio.jpg';
import HangSengBank from './Logos/HangSengBank.jpg';
import Hola from './Logos/Hola.jpg';
import IBS from './Logos/IBS.jpg';
import JustProduction from './Logos/JustProduction.jpg';
import KeyProduction from './Logos/KeyProduction.jpg';
import KongTak from './Logos/KongTak.jpg';
import LINKCentre from './Logos/LINKCentre.jpg';
import Lundbeck from './Logos/Lundbeck.jpg';
import Meji from './Logos/Meji.jpg';
import NPSolution from './Logos/NPSolution.jpg';
import OKDecorationDesignLtd from './Logos/OKDecorationDesignLtd.jpg';
import Orbis from './Logos/Orbis.jpg';
import PoLeungKuk from './Logos/PoLeungKuk.jpg';
import Purify from './Logos/Purify.jpg';
import SAHK from './Logos/SAHK.jpg';
import StJamesSettlement from './Logos/StJamesSettlement.jpg';
import ThePreface from './Logos/ThePreface.jpg';
import WatsonsWater from './Logos/WatsonsWater.jpg';
import WaYip from './Logos/WaYip.jpg';
import WiFiHK from './Logos/WiFiHK.jpg';
import WofooJosephLeePrimarySchool from './Logos/WofooJosephLeePrimarySchool.jpg';
import WofooSocialEnterprises from './Logos/WofooSocialEnterprises.jpg';
import YNOTFuniture from './Logos/YNOTFuniture.jpg';

export default function BoxLogos() {
    // TODO: Define useContext

    // TODO: Define variable
    const logos = [
        BoBunBeef,
        Hummingbird,
        TICA,
        ATHomeDesign,

        AMPM,
        DDPR,
        JustProduction,
        KeyProduction,

        Cedar,
        DecoFarmerStudio,
        OKDecorationDesignLtd,

        HangSengBank,
        WiFiHK,

        Meji,
        WatsonsWater,

        ChanKwong,
        LINKCentre,
        Orbis,
        PoLeungKuk,
        SAHK,
        StJamesSettlement,
        WofooJosephLeePrimarySchool,
        WofooSocialEnterprises,

        AMoment,
        BestWishes,
        YNOTFuniture,

        AKA,
        Chamberlain,
        CMCLHK,
        IBS,
        Hola,
        KongTak,
        Lundbeck,
        NPSolution,
        Purify,
        ThePreface,
        WaYip,
    ];

    // TODO: Define event

    // TODO: Define function

    // TODO: Return component
    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        }}>
            {
                logos.map((logo, i) => (
                    <Box
                        component="img"
                        key={i}
                        src={logo}
                        sx={{
                            height: { xs: '50px', md: '80px' },
                            mb: 2,
                            mr: 2,
                        }}
                    />
                ))
            }
        </Box>
    );
}